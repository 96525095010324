<script>
import Layout from "@/router/layouts/main";
import { api } from "@/state/services";
import { mapState } from "vuex";
import Banners from "@/components/widgets/banners";
import Stat from "@/components/widgets/stat";

import firebase from "firebase/app";
import "firebase/messaging";

export default {
  locales: {
    pt: {
      'Team': 'Time',
      'Dashboard': 'Painel',
      'Package': 'Pacote',
      'Graduation': 'Graduação',
      'Points': 'Pontos',
      'Referral link': 'Link de indicação',
      'We apologize, we are unable to retrieve information at this time. Please try again later.': 'Pedimos desculpas, não podemos recuperar informações no momento. Por favor, tente novamente mais tarde.',
      'No records found.': 'Nenhum registro encontrado.',
      'Total Bonus': 'Total de Bônus',
      'Total Points': 'Total de Pontos',
    },
    es: {
      'Team': 'Equipo',
      'Dashboard': 'Panel',
      'Package': 'Paquete',
      'Graduation': 'Graduación',
      'Points': 'Puntos',
      'Referral link': 'Link de patrocineo',
      'We apologize, we are unable to retrieve information at this time. Please try again later.': 'Nos disculpamos, no podemos recuperar información en este momento. Por favor, inténtelo de nuevo más tarde.',
      'No records found.': 'No se encontraron registros.',
      'Total Bonus': 'Total de Bônus',
      'Total Points': 'Total de Puntos',
    }
  },
  components: {
    Layout,
    Banners,
    Stat,
  },
  computed: {
    ...mapState({
      account: state => state.account
    }),
  },
  data() {
    return {
      total: {
        bonus: "R$ -,--",
        balance: "R$ -,--",
      },

      monthly: 'inactive',

      graduation: {
        id: null,
        name: '-',
        image: null
      },

      statData: null,
      wallets: null,

      notifications: true,
    };
  },
  methods: {
    getGraduation() {
      api
        .get('report/graduation')
        .then(response => {
          if (response.data.status=='success') {
            this.graduation.id = response.data.current.id
            this.graduation.name = response.data.current.name
            this.graduation.image = response.data.current.image
          }
        })
    },
    getMonthly() {
      api
        .get('report/monthly')
        .then(response => {
          this.monthly = response.data.status
          this.getStatData();
        })
    },
    getTotalBalance() {
      api
        .get('wallet/balance/network')
        .then(response => {
          if (response.data.status=='success') {
            this.total.balance = this.$options.filters.currency(response.data.balance)
            this.getStatData();
          }
        })
    },
    getStatData() {
      this.statData = [
        {
          title: "Usuário",
          value: this.account.user.username,
        },
        {
          title: "Plano",
          value: this.account.user.package.name,
        },
        {
          title: "Saldo Disponível",
          value: this.total.balance
        }
      ]
    },
    checkNotifications() {
      if (firebase.messaging.isSupported()) {
        api
          .get('user/notifications')
          .then(response => {
            if (response.data.status==='success') {
              if (response.data.devices == 'active') {
                this.notifications = true
              } else {
                this.notifications = false
              }
            } else {
              this.notifications = false
            }
          });
      }
    },
  },
  mounted() {
    this.getMonthly()
    this.getTotalBalance()
    this.getStatData()
    this.checkNotifications()
  }
};
</script>

<template>
  <Layout>
    <Banners></Banners>

    <div class="card">
      <div class="card-body py-2 d-flex align-items-center">
        <div class="mt-1">
          <i class="bx bx-link font-size-20 mr-1"></i>
        </div>
        <h5 class="m-0 align-items-center">
          {{ t("Link de Indicação") }}
        </h5>
        <div class="mt-1 ml-3">
          <ShareNetwork
            network="whatsapp"
            url=""
            :title="'https://clubekduda.com.br/' + account.user.username"
          >
            <i class="bx bxl-whatsapp font-size-24 text-dark p-0"></i>
          </ShareNetwork>
        </div>
        <div class="mt-1 ml-2">
          <button
            class="btn btn-link p-0 m-0"
            v-clipboard:copy="
              'https://clubekduda.com.br/' + account.user.username
            "
            v-on:click="$noty.success('O link foi copiado!')"
          >
            <i class="bx bx-copy font-size-20 text-dark"></i>
          </button>
        </div>
      </div>
    </div>

    <div class="row">
      <div v-for="stat of statData" :key="stat.icon" class="col-md-4 col-lg-4 col-xl-4">
        <Stat :type="stat.type" :icon="stat.icon" :title="stat.title" :value="stat.value" />
      </div>
    </div>
  </Layout>
</template>